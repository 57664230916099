import * as useTitle from './useTitle'
import * as useActivePopupStatus from './useActivePopupStatus'
import * as useProfileMessage from './useAlert'
import * as useUsersCount from './useUsersCount'
import * as useUnratedWebinar from './useUnratedWebinar'
import * as useSurvey from './useSurvey'
import * as selectors from './selectors'
import * as actions from './actions'
import * as reduce from './reducers'

const Common = {
  ...useTitle,
  ...useActivePopupStatus,
  ...useProfileMessage,
  ...useUsersCount,
  ...useUnratedWebinar,
  ...useSurvey,
  ...selectors,
  ...actions,
  ...reduce,
}

export default Common
