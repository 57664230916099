import { STATUSES } from 'components/parts/document-dispatch/file-input/config'

const INSURANCE_STRING_LENGTH = 14

enum InputNames {
  APPLICATION = 'application',
  PASSPORT = 'passport',
  CERTIFICATE = 'certificate',
  DIPLOMA = 'diploma',
  NAME_CHANGE = 'nameChange',
}

const requiredDocuments = [
  InputNames.APPLICATION,
  InputNames.PASSPORT,
  InputNames.CERTIFICATE,
  InputNames.DIPLOMA,
]

const profileDocuments = [
  InputNames.DIPLOMA,
  InputNames.PASSPORT,
  InputNames.CERTIFICATE,
  InputNames.NAME_CHANGE,
]

const wideProfileDocuments = [...profileDocuments, InputNames.APPLICATION]

const InputLabels = {
  [InputNames.APPLICATION]: 'Заявка на обучение с портала edu.rosminzdav.ru',
  [InputNames.PASSPORT]: 'Первый разворот паспорта',
  [InputNames.CERTIFICATE]: 'Сертификат о специальности или свидетельство об аккредитации',
  [InputNames.DIPLOMA]: 'Диплом о медицинском образовании',
  [InputNames.NAME_CHANGE]: 'Документ о смене ФИО (если актуально)',
}

const inputText = {
  [InputNames.APPLICATION]: [
    'Загрузите скан или фото подписанной заявки (<b>обязательно</b> с вашей подписью)',
  ],
  [InputNames.CERTIFICATE]: [
    'Загрузите последний действующий сертификат по соответствующей курсу специальности или свидетельство об аккредитации',
    'Дата выдачи сертификата — 2016 год или позднее<br> Свидетельство об аккредитации — с 1 по 5 страницу включительно',
  ],
  [InputNames.NAME_CHANGE]: ['Загрузите документ, подтверждающий ФИО в дипломе'],
}

const validExtensions = ['png', 'jpg', 'pdf']

export {
  InputNames,
  requiredDocuments,
  profileDocuments,
  wideProfileDocuments,
  InputLabels,
  inputText,
  STATUSES,
  validExtensions,
  INSURANCE_STRING_LENGTH,
}
