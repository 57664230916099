import ActionType from './action-type'
import { IMedcoinData, ISurveyData, IUnratedWebinar, IAdvertisingData } from '../data-types'
import { IAlertMessage } from 'constants/alert'

const ActionCreator = {
  setPageTitle: (pageTitle: string) => ({
    type: ActionType.SET_PAGE_TITLE,
    payload: { pageTitle },
  }),
  setLayoutStatus: (layoutStatus: boolean) => ({
    type: ActionType.SET_LAYOUT_ABSOLUTE,
    payload: { layoutStatus },
  }),
  setHashPopupStatus: (status?: string) => ({
    type: ActionType.SET_HASH_POPUP_STATUS,
    payload: { hashPopupStatus: status ? status : '' },
  }),
  setSingleAlert: (singleAlertMessage: IAlertMessage) => ({
    type: ActionType.SET_SINGLE_ALERT_MESSAGE,
    payload: { singleAlertMessage },
  }),
  deleteSingleAlert: (singleAlertMessage: IAlertMessage) => ({
    type: ActionType.DELETE_SINGLE_ALERT_MESSAGE,
    payload: { singleAlertMessage },
  }),
  setUsersCount: (usersCount: number) => ({
    type: ActionType.SET_USERS_COUNT,
    payload: { usersCount },
  }),
  setSurvey: (surveyData: ISurveyData) => ({
    type: ActionType.SET_SURVEY,
    payload: { surveyData },
  }),
  setIsOnline: (isOnline: boolean) => ({
    type: ActionType.SET_IS_ONLINE,
    payload: { isOnline },
  }),
  setUnratedWebinar: (unratedWebinar: IUnratedWebinar) => ({
    type: ActionType.SET_UNRATED_WEBINAR,
    payload: { unratedWebinar },
  }),
  setMedcoinData: (medcoinData: IMedcoinData) => ({
    type: ActionType.SET_MEDCOIN_DATA,
    payload: { medcoinData },
  }),
  setCoinsAmount: (amount: number | null) => ({
    type: ActionType.SET_COINS_AMOUNT,
    payload: { amount },
  }),
  setAdvertisingData: (advertisingData: IAdvertisingData) => ({
    type: ActionType.SET_ADVERSITING_DATA,
    payload: { advertisingData },
  }),
  setConsultationData: (consultationData: any) => ({
    type: ActionType.SET_CONSULTATION_DATA,
    payload: { consultationData },
  }),
}

export default ActionCreator
