import * as useNotifications from './useNotifications'
import * as selectors from './selectors'
import * as actions from './actions'
import * as reduce from './reducers'

const Common = {
  ...useNotifications,
  ...selectors,
  ...actions,
  ...reduce,
}

export default Common
