import { useCallback, useEffect } from 'react'

import Auth from 'store/auth'
import { useAuth } from 'store/auth/useAuth'
import useThrottle from 'hooks/useThrottle'

import { getRequest } from 'pages/api/axios'
import { AUTH_URL } from 'constants/data'
import { AuthStatus } from 'constants/auth/auth-data'

const useGetToken = () => {
  const { setAccessToken } = Auth.useHandleAuth()
  const { authorizationStatus } = useAuth()

  const getToken = useCallback(async () => {
    if (authorizationStatus === AuthStatus.NOT_AUTHORIZED) return
    try {
      const refreshData = await getRequest(AUTH_URL + '/refresh')
      const { accessToken, expireTime: expDate, currentTime: curTime } = refreshData.data
      setAccessToken(accessToken, expDate, curTime)
    } catch {
      console.log(' не удалось получить accessToken')
      throw Error
    }
  }, [setAccessToken])

  const handleGetToken = useCallback(async () => {
    console.log('socket: запрос на accessToken')

    try {
      getToken()
      console.log('socket: setAccessToken', new Date())
    } catch {
      console.log('socket: не удалось получить accessToken')
    }
  }, [setAccessToken])

  const throttledGetToken = useCallback(useThrottle(5000, false, handleGetToken), [handleGetToken])

  useEffect(() => {
    //@ts-expect-error не нашел нормальных типов
    window.getToken = throttledGetToken
  }, [])

  return { getToken, throttledGetToken }
}

export default useGetToken
