import { API_URL, NEST_API_URL, AUTH_URL, GAME_URL, STATISTIC_SERVICE_URL } from './data'

const apiPath = `${API_URL}/api`
const nestApiPath = `${NEST_API_URL}/api`

const BASE_URL = {
  WEBINAR: `${apiPath}/webinar`,
  ARTICLE: `${apiPath}/article`,
  CLINICAL_CASE: `${nestApiPath}/clinic`,
  INTERACTIVE: `${nestApiPath}/interactive`,
  LECTUR: `${apiPath}/lectur`,
  FEEDBACK: `${nestApiPath}/feedback`,
  SUBSCRIPTION: `${apiPath}/subscribe`,
  RECOMMENDATION: `${apiPath}/recomendations`,
  NEWSLINE: `${apiPath}/newsline`,
  FORM: `${nestApiPath}/form`,
  PAYMENT: `${apiPath}/payment`,
  QUESTIONNAIRE: `${nestApiPath}/questionnaire`,
  NMO_COURSE: `${nestApiPath}/nmoCourse`,
  DOCUMENTS: `${apiPath}/user-documents`,
  PODCAST: `${nestApiPath}/podcast`,
  VIDEOPROJECTS: `${nestApiPath}/playlist`,
  LEAD_MAGNIT: `${nestApiPath}/lead-magnit`,
  LEAD_MAGNIT_USER: `${nestApiPath}/lead-magnit-user`,
}

const ATTACHMENT_URL = {
  CLINICAL_CASE: `${nestApiPath}/clinic/files`,
}

const SubscibeURL = {
  LECTUR: `${BASE_URL.SUBSCRIPTION}/lectur`,
  WEBINAR: `${BASE_URL.SUBSCRIPTION}/webinar`,
  ARTICLE: `${BASE_URL.SUBSCRIPTION}/article`,
}

const URL_LIST = {
  NEXT_WEBINAR: `${BASE_URL.WEBINAR}?StatusId=3&limit=5`,
  ACTIVE_WEBINAR: `${BASE_URL.WEBINAR}?StatusId=2&limit=5`,
  PREV_WEBINAR: `${BASE_URL.WEBINAR}?StatusId=1&limit=4`,
  LAST_WATCH: `${BASE_URL.WEBINAR}/lastWatch`,
  //
  SELECTED_NEXT_WEBINAR: `${SubscibeURL.WEBINAR}?StatusId=3`,
  SELECTED_ACTIVE_WEBINAR: `${SubscibeURL.WEBINAR}?StatusId=2`,
  //
  VIEWS_COUNT: `${STATISTIC_SERVICE_URL}/uniqueViews`,
  FUTURE_PAGE_VISIT: `${STATISTIC_SERVICE_URL}/uniqueFuturePageVisits`,
  LIKE: `${STATISTIC_SERVICE_URL}/like`,
  RATE: `${STATISTIC_SERVICE_URL}/rate`,
  REPOST: `${STATISTIC_SERVICE_URL}/repost`,
  ARTICLE: `${BASE_URL.ARTICLE}?limit=4`,
  SUBSCRIBERS_COUNT: `${apiPath}/subscribe/countsubs`,
  //
  NEWSLINE_PERSONAL: `${BASE_URL.NEWSLINE}/personal`,
  NEWSLINE_COMMON: `${BASE_URL.NEWSLINE}/common`,
  NEWSLINE_QUESTIONNAIRE: `${BASE_URL.NEWSLINE}/questionnaire`,
  NEWSLINE_NEXT_WEBINARS: `${BASE_URL.NEWSLINE}/nearestWebinars`,
  NEWSLINE_NEXT_SPEC_WEBINARS: `${BASE_URL.NEWSLINE}/nearestSpecWebinars`,
  //
  PAYMENT_NEW: `${BASE_URL.PAYMENT}/new`,
  PAYMENT_STATUS: `${BASE_URL.PAYMENT}/status`,
  PAYMENT_CHECK: `${BASE_URL.PAYMENT}/check`,
  PAYMENT_PROMOCODE: `${BASE_URL.PAYMENT}/promo`,
  //
  DOCUMENT_STATUS: `${BASE_URL.DOCUMENTS}/status/`,
  DOCUMENT_APPLICATION: `${BASE_URL.DOCUMENTS}/application`,
  DOCUMENT_INSURANCE: `${BASE_URL.DOCUMENTS}/insurance`,
  DOCUMENT_CERTIFICATE: `${BASE_URL.DOCUMENTS}/certificate`,
  //
  GAME_START: `${GAME_URL}/game/create`, // +++
  GAME_CONFIG_ACTIONS: `${GAME_URL}/game/configActions`, // +++
  GAME_CONFIG_USER: `${GAME_URL}/game/configExperience`,
  GAME_PURCHASE: `${GAME_URL}/game/byPromo`, // +++
  GAME_MARKETPLACE_ALL: `${GAME_URL}/game/marketplace`, // +++
  GAME_MARKETPLACE_RECEIVED: `${GAME_URL}/game/purchaseGame`, // +++
  GAME_HISTORY: `${GAME_URL}/game/statistic`,
  GAME_AWARDS: `${GAME_URL}/game/awards`,
  GAME_LINK_INVITATION: `${GAME_URL}/game/linkInvitation`, // +++
  GAME_INVITED_USERS: `${GAME_URL}/game/getInvitedUsers`, // +++
  // GAME_USER: `${GAME_URL}/game/user`, // +++
  ESIA_AUTH_LINK: `${AUTH_URL}/esia/authLink`,
  ESIA_AUTH_TOKENS: `${AUTH_URL}/esia/authTokens`,
  //
  FORMAT_WEBINAR_KEY_MOMENTS: `${nestApiPath}/webinar_moment/many`,
  FORMAT_WEBINAR_AUDIO: `${nestApiPath}/webinar_audio`,
  FORMAT_WEBINAR_READ: `${nestApiPath}/webinar_article`,
  FORMAT_WEBINAR_ACTIVE_OPTIONS: `${apiPath}/webinar/specWebinarFilesList`,
  //
  NMO_SUBSCRIBE: `${BASE_URL.NMO_COURSE}/subscribeCourse`,
  //
}
const userURL = `${nestApiPath}/user`
const profileURL = `${userURL}/lk`

const PersonaURL = {
  PERSONAL_EMAIL_CONFIG: `${profileURL}/config`,
  WATCH_HISTORY: `${profileURL}/webinarList`,
  GET_TOTAL_POINTS: `${profileURL}/getTotalPoints`,
  GET_COURSES_LIST: `${profileURL}/coursesList`,
  SET_WEBINAR_WATCHED: `${userURL}/view`,
  SET_VISIT_HISTORY: `${userURL}/setVisitHistory`,
  NMO_CODES: `${apiPath}/nmo_codes`,
}

export {
  BASE_URL,
  ATTACHMENT_URL,
  URL_LIST,
  PersonaURL,
  profileURL,
  SubscibeURL,
  apiPath,
  nestApiPath,
}
