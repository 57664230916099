enum NavLineStatus {
  ACTIVE_LINE = 'ACTIVE_LINE',
  REC_LINE_CLOSED = 'REC_LINE_CLOSED',
  PROFILE_LINE_CLOSED = 'PROFILE_LINE_CLOSED',
  DOZATOR_LINE_DISABLED = 'DOZATOR_LINE_DISABLED',
}

enum NavLine {
  REC_LINE = 'REC_LINE',
  PROFILE_LINE = 'PROFILE_LINE',
  ALL_CLOSED = '',
}

type INavLine = {
  [key in NavLineStatus]?: NavLine | boolean
}

interface IInfromData {
  id: number
  name: string
  points: number
  teaser?: string
  lineClosed?: boolean
}

export { NavLineStatus, NavLine }
export type { INavLine, IInfromData }
