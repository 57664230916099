import GTag from './gtagMethods'
import YA from './yandexMethods'
import AM from './amplitudeMethods'

const setUserId = (userid: string): void => {
  try {
    GTag?.set({ field: 'user_id', value: userid })
  } catch (err) {
    console.warn('problem with google: setUserId', err)
  }

  try {
    YA?.set('setUserID', userid)
    YA?.set('userParams', { UserID: userid })
  } catch (err) {
    console.warn('problem with ya: setUserId', err)
  }

  try {
    AM.setUserId(userid)
  } catch (err) {
    console.warn('problem with amplitude: setUserId', err)
  }
}

// возможно сюда потребуется добавить запрос для YA, но пока инфы нет, пусть пушит только в гугл
const GTAGLoginGA = (): void => {
  try {
    GTag.event('login')
  } catch {
    console.warn('problem with login gtag')
  }
}

const GTAGSignUp = (): void => {
  try {
    GTag.event('sign_up')
    GTAGLoginGA()
  } catch {
    console.warn('problem with signup gtag')
  }
}

const YaMetricHit = (url: string): void => {
  try {
    YA?.set('hit', url)
  } catch {
    console.warn('problem with ya: hit')
  }
}

export { YaMetricHit, setUserId, GTAGLoginGA, GTAGSignUp }
