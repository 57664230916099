import { useSelector } from 'react-redux'
import useAnalyticsActions from './useAnalyticsActions'
import { getNMOPoints, getSource } from './selectors'

export const useNMO = () => {
  const actions = useAnalyticsActions()
  const NMOPoints = useSelector(getNMOPoints)
  const source = useSelector(getSource)

  return {
    source,
    NMOPoints,
    setNMOPoings: actions.setNMOPoings,
    setSource: actions.setSource,
  }
}

export default useNMO
