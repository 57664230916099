import { useRef, useEffect } from 'react'

import Auth from 'store/auth'
import useGetToken from 'hooks/useGetToken'

import { SECOND, MINUTE } from 'constants/timeslot'

const SHIFT_MINUTES = 4 * MINUTE
const RECONNECT_TIME = 30 * SECOND
const MAX_TIME = 2147483647

const useAccessToken = () => {
  const refreshTimer = useRef<any>(null)
  const { expireTime, currentTime } = Auth.useAuth()
  const { getToken } = useGetToken()

  const clearTimer = () => {
    const timer = refreshTimer.current
    if (timer) {
      clearTimeout(timer)
    }
  }

  const handleGetToken = async () => {
    console.log('timer: запрос на accessToken')

    try {
      getToken()
      console.log('timer: setAccessToken', new Date())
    } catch {
      console.log('timer: не удалось получить accessToken')
      refreshTimer.current = setTimeout(handleGetToken, RECONNECT_TIME)
    }
  }

  const refreshToken = async () => {
    clearTimer()
    const leftTime = expireTime - currentTime - SHIFT_MINUTES

    refreshTimer.current = setTimeout(handleGetToken, Math.min(MAX_TIME, leftTime))
  }

  useEffect(() => {
    if (expireTime) refreshToken()
  }, [expireTime])
}

export default useAccessToken
