import { IAnyObj } from 'constants/types/common.types'

const getUserProfile = (state: IAnyObj) => state.auth.userProfile
const getAuthorization = (state: IAnyObj) => state.auth.authorizationStatus
const getAccessToken = (state: IAnyObj) => state.auth.accessToken
const getCookieToken = (state: IAnyObj) => state.auth.isCookieToken
const getExpirationTime = (state: IAnyObj) => state.auth.expireTime
const getCurrentTime = (state: IAnyObj) => state.auth.currentTime

export {
  getUserProfile,
  getAuthorization,
  getAccessToken,
  getCookieToken,
  getExpirationTime,
  getCurrentTime,
}
