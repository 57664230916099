import { useSelector } from 'react-redux'
import { useEffect } from 'react'

import useListActions from './useListActions'
import { sortByLabel } from '../../utils/search/sortByLabel'
import { getAxiosArr } from '../../pages/api/axios'
import { CommonURL, Key } from '../../constants/search-data/search-data'
import { getSpecialities } from './selectors'

interface ISpecialities {
  label: string
  value: number | string | Date
  labelEng?: string
}

const formatSpecialities = (allSpecialities?: any[]) => {
  return sortByLabel(
    allSpecialities?.map((item) => ({
      value: item.id,
      label: item.name,
      labelEng: item.name_en,
      key: Key.SPECIALITIES,
    })) || []
  )
}

let isCalled = false

const useSpecialities = (initSpecialities?: ISpecialities[]): ISpecialities[] => {
  const { setSpecialities } = useListActions()
  const specialities = useSelector(getSpecialities)

  useEffect(() => {
    const getNewSpecilities = async () => {
      isCalled = true
      const allSpecialities = await getAxiosArr(CommonURL.SPECIALITY)

      const formatedSpecialities = formatSpecialities(allSpecialities)
      setSpecialities(formatedSpecialities)
    }

    if (!specialities.length && initSpecialities) setSpecialities(initSpecialities)
    else if (!specialities.length && !isCalled) getNewSpecilities()
  }, [])

  return specialities
}

export default useSpecialities
export { formatSpecialities }
export type { ISpecialities }
