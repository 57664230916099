const MediaNames = {
  MOBILE_SMALL: 'mobileSmall',
  MOBILE: 'mobile',
  TABLET: 'tablet',
  TABLET_MAX: 'tabletMax',
  PC_MIN: 'pcMin',
  PC_MAX: 'pcMax',
}

const queries = [
  {
    label: MediaNames.MOBILE_SMALL,
    conditions: [0, 576],
  },
  {
    label: MediaNames.MOBILE,
    conditions: [576, 768],
  },
  {
    label: MediaNames.TABLET,
    conditions: [768, 1024],
  },
  {
    label: MediaNames.TABLET_MAX,
    conditions: [1024, 1280],
  },
  {
    label: MediaNames.PC_MIN,
    conditions: [1280, 1440],
  },
  {
    label: MediaNames.PC_MAX,
    conditions: [1440, Infinity],
  },
]

const ScreenSize = {
  standart: 'standart',
  big: 'big',
  mobile: 'mobile',
}

const PCScreens = [MediaNames.PC_MIN, MediaNames.PC_MAX]
const bigScreens = [MediaNames.TABLET_MAX, MediaNames.PC_MIN, MediaNames.PC_MAX]
const mobileScreens = [MediaNames.MOBILE_SMALL, MediaNames.MOBILE]

export { MediaNames, queries, ScreenSize, PCScreens, bigScreens, mobileScreens }
