import { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'

import useCommonActions from './useCommonActions'
import { getInternetConnection } from './selectors'

const useInternetConnection = () => {
  const actions = useCommonActions()
  const isOnline = useSelector(getInternetConnection)
  const timer = useRef<any>(null)

  const setIsOnline = useCallback((onlineStatus: boolean) => {
    clearTimeout(timer.current)
    if (onlineStatus) {
      actions.setIsOnline(onlineStatus)
      return
    }

    timer.current = setTimeout(() => {
      actions.setIsOnline(onlineStatus)
    }, 10000)
  }, [])

  return {
    isOnline,
    setIsOnline,
  }
}

export default useInternetConnection
