import ActionType from './action-type'

const ActionCreator = {
  setNMOPoings: (NMOPoints: number) => ({
    type: ActionType.SET_PAGE_NMO,
    payload: { NMOPoints },
  }),
  setSource: (source: string) => ({
    type: ActionType.SET_SOURCE,
    payload: { source },
  }),
}

export default ActionCreator
