import * as useBannerTelegramBottom from './useBannerTelegramBottom'

import * as selectors from './selectors'
import * as actions from './actions'
import * as reduce from './reducers'

const Popups = {
  ...useBannerTelegramBottom,
  ...selectors,
  ...actions,
  ...reduce,
}

export default Popups
