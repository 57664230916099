import { useSelector } from 'react-redux'

import useCommonActions from './useCommonActions'
import { getAlerts } from './selectors'

const useAlert = () => {
  const actions = useCommonActions()
  const alerts = useSelector(getAlerts)

  return {
    alerts,
    setSingleAlert: actions.setSingleAlert,
    deleteSingleAlert: actions.deleteSingleAlert,
  }
}

export default useAlert
