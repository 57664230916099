import ActionType from './action-type'

const ActionCreator = {
  openBannerTelegramBottom: () => ({
    type: ActionType.OPEN_BANNER_TELEGRAM_BOTTOM,
  }),
  closeBannerTelegramBottom: () => ({
    type: ActionType.CLOSE_BANNER_TELEGRAM_BOTTOM,
  }),
}

export default ActionCreator
