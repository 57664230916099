import { useEffect, ReactNode, FC } from 'react'
import Router from 'next/router'

import useActivePopupStatus from 'store/common/useActivePopupStatus'

import { popupName } from 'constants/auth/auth-data'
import { RouterEvents } from 'constants/events'

interface IHashWrap {
  children: ReactNode
}

const HashWrap: FC<IHashWrap> = ({ children }: IHashWrap) => {
  const { setHashPopupStatus } = useActivePopupStatus()

  useEffect(() => {
    const hash = window.location.hash.replace('#', '')
    const authState = popupName[hash as popupName]

    setHashPopupStatus(authState)
    const handleHashChange = (url: string) => {
      const formedHash = url?.split('#')[1]
      setHashPopupStatus(popupName[formedHash as popupName])
    }

    const handleURLChangeComplete = (url: string) => {
      handleHashChange(url)
    }

    Router.events.on(RouterEvents.HASH_CHANGE_COMPLETE, handleHashChange)
    Router.events.on(RouterEvents.ROUTE_CHANGE_START, setHashPopupStatus)
    Router.events.on(RouterEvents.ROUTE_CHANGE_COMPLETE, handleURLChangeComplete)

    return () => {
      Router.events.off(RouterEvents.HASH_CHANGE_COMPLETE, handleHashChange)
      Router.events.off(RouterEvents.ROUTE_CHANGE_START, setHashPopupStatus)
      Router.events.off(RouterEvents.ROUTE_CHANGE_COMPLETE, handleURLChangeComplete)
    }
  }, [])

  return <>{children}</>
}

export default HashWrap
