import { ReactNode, FC } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import AuthWrap from 'containers/auth-wrap/auth-wrap'
import HashWrap from 'containers/hash-wrap/hash-wrap'
import WindowSizeWrap from 'containers/window-size-wrap/window-size-wrap'
import StatisticWrap from 'containers/statistic-wrap/statistic-wrap'
import AnaliticsParamsWrap from 'containers/analitics-params-wrap/analitics-params-wrap'
import AdvertisingWrap from 'containers/advertising-wrap/advertising-wrap'
import { GABody } from 'components/global/analytics/google-head'
import MedcoinDataWrap from 'containers/medcoin-data-wrap/medcoin-data-wrap'
interface ILikeWrap {
  children: ReactNode
}

const GlobalWrap: FC<ILikeWrap> = ({ children }: ILikeWrap) => (
  <>
    <GABody />
    <AuthWrap>
      <StatisticWrap>
        <HashWrap>
          <WindowSizeWrap>
            <AnaliticsParamsWrap>
              <AdvertisingWrap>
                <MedcoinDataWrap>
                  <SkeletonTheme baseColor="#eaf3f9" highlightColor="white">
                    {children}
                  </SkeletonTheme>
                </MedcoinDataWrap>
              </AdvertisingWrap>
            </AnaliticsParamsWrap>
          </WindowSizeWrap>
        </HashWrap>
      </StatisticWrap>
    </AuthWrap>
  </>
)

export default GlobalWrap
