import { FC, useState, useEffect, useMemo } from 'react'

import ScreenSizeContext from 'context/screenSizeContext'

import { ScreenSize, bigScreens, PCScreens, mobileScreens } from 'constants/media-constanst'
import { queries } from 'constants/media-constanst'
import { Events } from 'constants/events'

interface IProps {
  children: React.ReactNode
}

const checkWindowSize = () => {
  const width = window.innerWidth
  return queries.find((query) => width >= query.conditions[0] && width < query.conditions[1])?.label
}

const WindowSizeWrap: FC<IProps> = ({ children }: IProps) => {
  const [windowSize, setWindowSize] = useState(queries[0].label)

  const changeWindowSize = () => {
    const resultQuery = checkWindowSize()
    if (resultQuery) setWindowSize(resultQuery)
  }

  useEffect(() => {
    changeWindowSize()
    window.addEventListener(Events.RESIZE, changeWindowSize)

    return () => {
      window.removeEventListener(Events.RESIZE, changeWindowSize)
    }
  }, [])

  const windowSizeCompare = useMemo(
    () => ({
      compare: {
        [ScreenSize.big]: PCScreens.includes(windowSize),
        [ScreenSize.standart]: bigScreens.includes(windowSize),
        [ScreenSize.mobile]: mobileScreens.includes(windowSize),
      },
      windowSize,
    }),
    [windowSize]
  )

  return (
    <ScreenSizeContext.Provider value={windowSizeCompare}>{children}</ScreenSizeContext.Provider>
  )
}

export default WindowSizeWrap
