enum popupName {
  notActive = 'notActive',
  login = 'login',
  loginStudents = 'loginStudents',
  registration = 'registration',
  registrationStudents = 'registrationStudents',
  registratiomStudentsOlymp = 'registratiomStudentsOlymp',
  registrationWithSteps = 'registrationWithSteps',
  nopassRegistration = 'nopassRegistration',
  anketa = 'anketa',
  anketaWebinar = 'anketaWebinar',
  anketaWithWarning = 'anketaWithWarning',
  registrationSuccess = 'registrationSuccess',
  recoverPassword = 'recoverPassword',
  recoverSuccess = 'recoverSuccess',
  onboarding = 'onboarding',
  geriatricsSchool = 'geriatricsSchool',
  loginGeriatricsSchool = 'loginGeriatricsSchool',
}

const AuthStatus = {
  NOT_AUTHORIZED: 1,
  AUTHORIZED: 2,
}

export { popupName, AuthStatus }
