import { IState } from '../data-types'
import { IOptions } from '../../constants/types/common.types'

const getLectors = (state: IState): IOptions[] => state.lists.lectors || []
const getRegions = (state: IState): IOptions[] => state.lists.regions || []
const getCountries = (state: IState): IOptions[] => state.lists.countries || []
const getSpecialities = (state: IState): IOptions[] => state.lists.specialities || []
const getThemes = (state: IState): IOptions[] => state.lists.themes || []

export { getLectors, getRegions, getCountries, getSpecialities, getThemes }
