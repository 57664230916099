const ActionType = {
  SET_NOTIFICATION_MESSAGES: 'SET_NOTIFICATION_MESSAGES',
  SET_SINGLE_NOTIFICATION: 'SET_SINGLE_NOTIFICATION',
  SET_LINE_STATUS: 'SET_LINE_STATUS',
  SET_NAVBAR_HEIGHT: 'SET_NAVBAR_HEIGHT',
  SET_ACTIVE_WEBINARS: 'SET_ACTIVE_WEBINARS',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_DOZATOR_LINE: 'SET_DOZATOR_LINE',
}

export default ActionType
