import ActionType from './action-type'
import { IAnyObj } from 'constants/types/common.types'

const ActionCreator = {
  setCurrentProfile: (userProfile: IAnyObj) => ({
    type: ActionType.SET_CURRENT_PROFILE,
    payload: {
      userProfile,
    },
  }),
  setAccessToken: (accessToken: string, expireTime: number, currentTime: number) => ({
    type: ActionType.SET_ACCESS_TOKEN,
    payload: {
      accessToken,
      expireTime,
      currentTime,
    },
  }),
  setAuth: ({ userProfile, accessToken, expireTime, currentTime }: IAnyObj) => ({
    type: ActionType.SET_AUTH,
    payload: {
      userProfile,
      accessToken,
      expireTime,
      currentTime,
    },
  }),
  removeAuthorization: () => ({
    type: ActionType.REMOVE_AUTHORIZATION,
  }),
}

export default ActionCreator
