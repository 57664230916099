import { useMemo } from 'react'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import { reducer, inititalStoreState } from './reducers'
import { IAnyObj } from 'constants/types/common.types'

let store: any

function initStore(preloadedState = inititalStoreState) {
  return createStore(reducer, preloadedState, composeWithDevTools(applyMiddleware()))
}

interface IStore {
  auth: {
    userProfile: IAnyObj
    authorizationStatus: number
    accessToken: string
    expireTime: number
    currentTime: number
  }
}

export const initializeStore = (preloadedState: IStore) => {
  let _store = store ?? initStore(preloadedState)
  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    })
    // Reset the current store
    store = undefined
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function useStore(initStor: IStore) {
  const currentStore = useMemo(() => initializeStore(initStor), [initStor])
  return currentStore
}
