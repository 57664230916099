import { useSelector } from 'react-redux'

import useCommonActions from './useCommonActions'
import { getSurvey, getSurveyLanguage } from './selectors'
import { IObj } from 'constants/types/common.types'

type Variant = { txt: string; isRightAnswer?: boolean }

const emptyArr: Variant[] = []
const emptyObj: IObj<number | boolean> = {}

const useSurvey = () => {
  const actions = useCommonActions()
  const survey = useSelector(getSurvey)
  const surveyLanguage = useSelector(getSurveyLanguage)

  const status = survey?.state?.status
  const question = survey?.activeVote?.languages?.[surveyLanguage]?.question || '' // текст вопроса
  const variants =
    (survey?.activeVote?.languages?.[surveyLanguage]?.variants as Variant[]) || emptyArr // варианты ответа
  const variantsType = survey?.activeVote?.variantsType // radio / checkbox - сколько вариантов ответа можно выбрать
  const total = survey?.total || 0
  const closed = survey?.closed || 0
  const results = survey?.results || emptyObj
  const checked = survey?.checked || emptyObj
  const userAnswer = survey?.state?.variants || emptyObj
  const voteId = survey?.state?.voteId || ''
  const pointsCost = survey?.activeVote?.pointsCost || 0 // сколько баллов стоит данный вопрос
  const userQuizzResult = survey?.userQuizzRes
  const pollEndDate = survey?.activeVote?.pollEndDate || '' // когда закончится голосование
  const pollTimeLeft = survey?.activeVote?.pollTimeLeft || 0 //

  return {
    survey,
    status,
    question,
    variants,
    variantsType,
    surveyLanguage,
    setSurvey: actions.setSurvey,
    total,
    closed,
    results,
    checked,
    userAnswer,
    voteId,
    pointsCost,
    userQuizzResult,
    pollEndDate,
    pollTimeLeft,
  }
}

export default useSurvey
