import ActionType from './action-type'
import { IOptions } from '../../constants/types/common.types'

const ActionCreator = {
  setLectures: (lectures?: IOptions[]) => ({
    type: ActionType.SET_LECTURES,
    payload: lectures,
  }),
  setRegions: (regions?: IOptions[]) => ({
    type: ActionType.SET_REGIONS,
    payload: regions,
  }),
  setSpecialities: (specialities?: IOptions[]) => ({
    type: ActionType.SET_SPECIALITIES,
    payload: specialities,
  }),
  setThemes: (themes?: IOptions[]) => ({
    type: ActionType.SET_THEMES,
    payload: themes,
  }),
  setCountries: (countries?: IOptions[]) => ({
    type: ActionType.SET_COUNTRIES,
    payload: countries,
  }),
}

export default ActionCreator
