import ActionType from './action-type'
import { IReducer } from '../data-types'
import { IStringObj } from '../data-types'

const initialState = {
  pageTitle: '',
  hashPopupStatus: '',
  alerts: [] as IStringObj[],
  usersCount: -1,
  survey: { state: {} },
  surveyLanguage: 'RU',
  isOnline: true,
  unratedWebinar: {},
  medcoinData: {},
  advertisingData: { origin: '', visitCounter: null },
  coins: null,
}

const reducer = (state = initialState, { type, payload }: IReducer): any => {
  switch (type) {
    case ActionType.SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: payload.pageTitle,
      }
    case ActionType.SET_WEBINAR_DATA:
      return {
        ...state,
        webinarData: payload.webinarData,
      }
    case ActionType.SET_LAYOUT_ABSOLUTE:
      return {
        ...state,
        layoutStatus: payload.layoutStatus,
      }
    case ActionType.SET_HASH_POPUP_STATUS:
      return {
        ...state,
        hashPopupStatus: payload.hashPopupStatus,
      }
    case ActionType.SET_ALERT_MESSAGES:
      return {
        ...state,
        alerts: payload.alerts,
      }
    case ActionType.SET_SINGLE_ALERT_MESSAGE:
      if (state.alerts.find((msg) => msg.id === payload.singleAlertMessage.id))
        return { ...state, alerts: [...state.alerts] }
      return {
        ...state,
        alerts: [...state.alerts, payload.singleAlertMessage],
      }

    case ActionType.DELETE_SINGLE_ALERT_MESSAGE:
      return {
        ...state,
        alerts: state.alerts.filter((msg) => msg.id !== payload.singleAlertMessage.id),
      }
    case ActionType.SET_USERS_COUNT:
      return {
        ...state,
        usersCount: payload.usersCount,
      }

    case ActionType.SET_SURVEY:
      return {
        ...state,
        survey: payload.surveyData,
      }

    case ActionType.SET_IS_ONLINE:
      return {
        ...state,
        isOnline: payload.isOnline,
      }

    case ActionType.SET_UNRATED_WEBINAR:
      return {
        ...state,
        unratedWebinar: payload.unratedWebinar,
      }

    case ActionType.SET_MEDCOIN_DATA:
      return {
        ...state,
        medcoinData: payload.medcoinData,
      }

    case ActionType.SET_COINS_AMOUNT:
      return {
        ...state,
        coins: payload.amount,
      }
    case ActionType.SET_ADVERSITING_DATA:
      return {
        ...state,
        advertisingData: payload.advertisingData,
      }
    case ActionType.SET_CONSULTATION_DATA:
      return {
        ...state,
        consultationData: payload.consultationData,
      }

    default:
      return state
  }
}

export { reducer }
