import { FC, ReactElement } from 'react'
import Script from 'next/script'

import { GA_TRACKING_ID, GTM_ID } from 'constants/data'

const GAHead = (): ReactElement => (
  <>
    <Script
      strategy="afterInteractive"
      src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
    ></Script>
    <Script id="gaHead" strategy="afterInteractive">
      {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_TRACKING_ID}');`}
    </Script>
  </>
)

const GABody: FC = () => (
  <noscript>
    <iframe
      src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
      height="0"
      width="0"
      style={{ display: 'none', visibility: 'hidden' }}
      loading="lazy"
    ></iframe>
  </noscript>
)

export { GAHead, GABody }
