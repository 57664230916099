import ActionType from './action-type'
import { IReducer } from '../data-types'

interface IPopupsState {
  isBannerTelegramBottomOpen: boolean
}

const initialState: IPopupsState = {
  isBannerTelegramBottomOpen: false,
}

const reducer = (state = initialState, { type }: IReducer): any => {
  switch (type) {
    case ActionType.OPEN_BANNER_TELEGRAM_BOTTOM:
      return { ...state, isBannerTelegramBottomOpen: true }
    case ActionType.CLOSE_BANNER_TELEGRAM_BOTTOM:
      return { ...state, isBannerTelegramBottomOpen: false }
    default:
      return state
  }
}

export { reducer }
