import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import useCommonActions from './useCommonActions'
import { getMedcoinData, getCoinsAmount } from './selectors'
import { getAxiosSingle } from 'pages/api/axios'
import { profileURL } from 'constants/url'

const useMedcoinData = () => {
  const actions = useCommonActions()
  const medcoinData = useSelector(getMedcoinData)
  const coins = useSelector(getCoinsAmount)

  const updateCoinsAmount = useCallback(async () => {
    const data = await getAxiosSingle(profileURL)

    if (!isNaN(data?.coins)) {
      actions.setCoinsAmount(data?.coins)
    } else {
      actions.setCoinsAmount(0)
    }
  }, [])

  return {
    coins,
    medcoinData,
    updateCoinsAmount,
    setMedcoinData: actions.setMedcoinData,
  }
}

export default useMedcoinData
