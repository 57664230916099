import { IState, INotification, IReduxActiveWebinar } from '../data-types'
import { INavLine } from 'constants/types/navbar.types'

const getNotificationMessages = (state: IState): INotification[] =>
  state.navbar.notificationMessages
const getLineStatus = (state: IState): INavLine => state.navbar.lineStatus
const getSearchText = (state: IState): string => state.navbar.searchText
const getNavbarHeight = (state: IState): number => state.navbar.navbarHeight
const getActiveWebinars = (state: IState): IReduxActiveWebinar => state.navbar.activeWebinars

export { getNotificationMessages, getLineStatus, getNavbarHeight, getActiveWebinars, getSearchText }
