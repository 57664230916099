import { useEffect, ReactNode, FC } from 'react'
import { useRouter } from 'next/router'

import useAdversitingData from 'store/common/useAdversitingData'

import { LocalStorageKeys } from 'constants/storage'

interface IAdvertisingWrap {
  children: ReactNode
}

const AdvertisingWrap: FC<IAdvertisingWrap> = ({ children }) => {
  const { query } = useRouter()
  const { setAdversitingData } = useAdversitingData()

  useEffect(() => {
    const origin = query['utm_source'] || ''
    const initStorage = { origin, visitCounter: 0 }

    const dataAdvertising = localStorage.getItem(LocalStorageKeys.ADVERTISING_BANNERS)
    const formatedData = dataAdvertising ? JSON.parse(dataAdvertising) : initStorage

    setAdversitingData(formatedData)
  }, [])

  return <>{children}</>
}

export default AdvertisingWrap
