import ActionType from './action-type'
import { AuthStatus } from '../../constants/auth/auth-data'
import { IReducer } from '../data-types'

const initialState = {
  userProfile: {},
  authorizationStatus: AuthStatus.NOT_AUTHORIZED,
  accessToken: '',
  expireTime: 0,
  currentTime: 0,
}

const reducer = (state = initialState, { type, payload }: IReducer) => {
  switch (type) {
    case ActionType.SET_CURRENT_PROFILE:
      return {
        ...state,
        userProfile: { ...state.userProfile, ...payload.userProfile },
      }
    case ActionType.REMOVE_AUTHORIZATION:
      return {
        ...state,
        ...initialState,
        authorizationStatus: AuthStatus.NOT_AUTHORIZED,
      }
    case ActionType.SET_ACCESS_TOKEN:
      return {
        ...state,
        ...payload,
        authorizationStatus: AuthStatus.AUTHORIZED,
      }
    case ActionType.SET_AUTH:
      return {
        ...state,
        userProfile: { ...state.userProfile, ...payload.userProfile },
        accessToken: payload.accessToken || state.accessToken,
        expireTime: payload.expireTime || state.expireTime,
        currentTime: payload.currentTime || state.currentTime,
        authorizationStatus: AuthStatus.AUTHORIZED,
      }

    default:
      return state
  }
}

export { initialState, reducer }
