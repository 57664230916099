enum SocketEvents {
  // Вебинары
  WEBINAR_STATE = 'webinarState',
  CHAT_MESSAGE = 'chatMessage',
  UPDATE_MESSAGE = 'updateMessage',
  READY = 'ready',
  WEBINAR_INFO = 'webinarInfo',
  VOTE_STATE = 'voteState',
  SHOW_QUIZZ_RESULTS = 'showQuizzResults',
  CHECKUP = 'checkup',
  CHECKUP_SUBMIT = 'checkup: submit',
  CHECKUP_SHOW = 'checkup: show',
  GET_MESSAGES = 'getMessages',
  SUBSCRIBE_ALL_LECTORS = 'subscribeAllLectors',
  RATING = 'rating',
  DELETE_SINGLE_MESSAGE = 'deleteSingleMessage',
  DELETE_ALL_USER_MESSAGES = 'deleteAllUserMessages',
  SEND_VOTES = 'sendVote',
  WEBINAR_ONBOARDING_CLOSE = 'webinarOnboardingClose',

  // Статистика
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  RELOAD = 'reload',
  LOGOUT = 'logout',
  CLIENT_LOG = 'clientLog',
  ENSURE_USER_ACCESS = 'ensureUserAccess',
  UPDATE_USER_PROFILE = 'updateUserProfile',
  REFRESH_TOKEN = 'refreshToken',
  VISIBILITY_CHANGE = 'visibilityChange',
  WINDOW_ERROR = 'windowError',
  SOCKET_ERROR = 'socketError',
  USERS_COUNT = 'usersCount',
  PAGE_VIEWED = 'pageViewed',
  GET_PROPS = 'getProps',
  WEBINAR_STAT = 'webinarStat',
  WEBINAR_RECORD = 'webinarRecord',
  WEBPUSH_REGISTRATION_TOKEN = 'webPushRegistrationToken',
  ACTIVE_WEBINAR = 'activeWebinar',
  ARTICLE_READ = 'articleRead',

  NOTIFICATIONS_STATE = 'notificatonsState',
  NOTIFICATIONS_UPDATE = 'notificationUpdate',
  READ_NOTIFICATIONS = 'readNotifications',
  DELETE_NOTIFICATIONS = 'deleteNotifications',
}

enum RouterEvents {
  ROUTE_CHANGE_START = 'routeChangeStart',
  ROUTE_CHANGE_ERROR = 'routeChangeError',
  ROUTE_CHANGE_COMPLETE = 'routeChangeComplete',
  HASH_CHANGE_COMPLETE = 'hashChangeComplete',
  HASH_CHANGE_START = 'hashChangeStart',
}

enum Events {
  KEYDOWN = 'keydown',
  RESIZE = 'resize',
  LOAD = 'load',
  ERROR = 'error',
  SCROLL = 'scroll',
  WHEEL = 'wheel',
  TOUCHSTART = 'touchstart',
  TOUCHEND = 'touchend',
  MOUSEUP = 'mouseup',
  MOUSEMOVE = 'mousemove',
  MOUSEOVER = 'mouseover',
  MOUSEOUT = 'mouseout',
  CLICK = 'click',
  ORIENTATION_CHANGE = 'orientationchange',
  VISIBILITY_CHANGE = 'visibilitychange',
  MESSAGE = 'message',
}

export { SocketEvents, RouterEvents, Events }
