import { useSelector } from 'react-redux'

import usePopupActions from './usePopupActions'
import { getBannerTelegramBottomStatus } from './selectors'

const useBannerTelegramBottom = () => {
  const actions = usePopupActions()
  const isBannerTelegramBottomOpen = useSelector(getBannerTelegramBottomStatus)

  return {
    isBannerTelegramBottomOpen,
    openBannerTelegramBottom: actions.openBannerTelegramBottom,
    closeBannerTelegramBottom: actions.closeBannerTelegramBottom,
  }
}

export default useBannerTelegramBottom
